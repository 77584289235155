import React, { useEffect, useState, useRef } from "react";
import { postcodeValidator } from "postcode-validator";
import { AppMessages } from "../../components/Constant/App_Messages";
import { MainHeaderMobile } from "../../components/Header/MainHeader.mobile";
import { FooterMobile } from "../../components/Footer/Footer.mobile";
import page from "../../page";
import LoaderSpinner from "../../components/LoaderSpinner.js/LoaderSpinner";
import ReactInputMask from "react-input-mask";
import "./HomePageMobile.css";
import service1 from "../../assets/images/home-service-1.svg";
import service2 from "../../assets/images/home-service-2.svg";
import service3 from "../../assets/images/home-service-3.svg";
import service4 from "../../assets/images/home-service-4.svg";
import service5 from "../../assets/images/home-service-5.svg";
import service6 from "../../assets/images/home-service-6.svg";
import service7 from "../../assets/images/home-service-7.svg";
import service8 from "../../assets/images/home-service-8.svg";
import phone from "../../assets/images/phone.svg";
import iconCheck from "../../assets/images/icon-check.svg";
import badge1 from "../../assets/images/badge-1.svg";
import badge2 from "../../assets/images/badge-2.svg";
import badge3 from "../../assets/images/badge-3.svg";
import badge4 from "../../assets/images/badge-4.svg";
import featured1 from "../../assets/images/featured-icon.svg";
import WebUserVerificationNew from "../../components/PhoneVerification/WebUserVerificationNew";
import tickIcon from "../../assets/images/tick.svg";
import { phoneNumberConstant } from "../../components/Constant/Constants.Mobile";
import { phoneNumberConstantCall } from "../../components/Constant/Constants.Mobile";
import banner from "../../assets/images/banner.png";

import tags from "../../assets/images/tags.png";


function HomePageMobile({ pageData }) {
  const validPhoneRgex = /^(?!0{10})[0-9]\d{9}$/;
  const [zipCode, setZipcode] = useState({
    code: "",
    message: "",
    isValid: false,
  });
  const [serviceListed, setServiceListed] = useState(false);
  const [responseZipCode, setResponseZipCode] = useState("");
  const [existNumberPopup, setExistNumberPopup] = useState(false);
    const [existWebNumberPopup, setExistWebNumberPopup] = useState(false);
    const [existCustomerWithoutAccount, setExistCustomerWithoutAccount] = useState(false);
  const [userType, setUserType] = useState("");
  const [otpPopup, setotpPopup] = useState(false);
  const [inputValues, setInputValues] = useState({
    phone: "",
    errorMessage: {
      phone: "",
    },
    isPhoneValid: false,
  });
  const [apiError, setApiError] = useState("");
  const [loadeSpin, setLoadSpin] = useState(false);
  const [redirectNeed, setRedirectNeed] = useState(false);
  const zipCodeRef = useRef(null);
  const phoneRef = useRef(null);

  const serviceImages = [
    { src: service1, alt: service1 },
    { src: service2, alt: service2 },
    { src: service3, alt: service3 },
    { src: service4, alt: service4 },
    { src: service5, alt: service5 },
    { src: service6, alt: service6 },
    { src: service7, alt: service7, specialClass: "h-12" },
    { src: service8, alt: service8, specialClass: "h-12" },
    ];

    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isFirstImageScrolled, setIsFirstImageScrolled] = useState(false);


    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        checkScreenSize();

        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);



    useEffect(() => {
        const img = new Image();
        img.src = banner;
        img.onload = () => {
            setImgDimensions({ width: img.width, height: img.height });
        };

        const style = document.createElement("style");
        style.innerHTML = `
      @keyframes scroll-right {
        0% {
          transform: translateX(0); /* Start off-screen to the right */
        }

        100% {
          transform: translateX(-100%); /* Move the image off-screen to the left */
        }
      }
    `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);



    const handleScrollEnd = () => {
        setIsFirstImageScrolled(true);
    };



  function validateZip(value) {
    const isValid = value && postcodeValidator(value, "US");
    setZipcode({
      code: value,
      message: !value
        ? AppMessages.zip_empty
        : !postcodeValidator(value, "US")
        ? AppMessages.zip_invalid
        : "",
      isValid: isValid,
    });
  }
  const zipCodeHandle = (e) => {
    const { value } = e.target;
    if (value.length == 0 || /^[0-9]+$/.test(value)) {
      validateZip(value.trim());
    }
  };

  function phoneNumberHandleChange(e, name, regex, emptyMSG, invalidMSG) {
    const value =
      e.target.value.slice(1, 4) +
      e.target.value.slice(6, 9) +
      e.target.value.slice(10, 15);
    const isValid = value && regex.test(value);
    let obj = { ...inputValues };
    obj[name] = value;
    obj["errorMessage"][name] = !value ? emptyMSG : !isValid ? invalidMSG : "";
    obj.isPhoneValid = isValid;

    setInputValues(obj);
  }

  useEffect(() => {
    setApiError("");
  }, [zipCode.code]);

  useEffect(() => {
    if (pageData?.inputValues?.phoneNumber) {
      setInputValues((prev) => ({
        ...prev,
        phone: pageData?.inputValues?.phoneNumber,
      }));
    }

    if (pageData?.inputValues?.zipCode) {
      setResponseZipCode(pageData?.inputValues?.zipCode);
      setZipcode((prev) => ({
        ...prev,
        code: pageData?.inputValues?.zipCode,
        isValid: postcodeValidator(pageData?.inputValues?.zipCode, "US"),
      }));
    }

    if (
      pageData?.inputValues?.zipCode &&
      pageData?.trustedServices?.services.length > 0 &&
      pageData?.inputValues?.phoneNumber
    ) {
      setServiceListed(true);
    }
  }, [pageData]);

  async function fetchServicesByZipCode() {
    setLoadSpin(true);
    try {
      const response = await fetch(
        `/api/Services/GetServicesByZipCode/${zipCode.code}`
      );
      if (!response.ok) {
        if (response.status === 400) {
          const text = await response.text();
          setApiError(text);
          setServiceListed(false);
        }
        setServiceListed(false);
        setLoadSpin(false);
        setResponseZipCode("");
      }

      const data = await response.json();
      if (data.responseCode === 200) {
        setServiceListed(true);
        setResponseZipCode(data?.data?.zipCode);
      }
      setLoadSpin(false);
    } catch (err) {
      setLoadSpin(false);
    }
  }

  function seeYourPriceFun() {
    if (zipCode.code && !zipCode.message) {
      let obj = { ...inputValues };
      obj.phone = inputValues.phone;
      obj.errorMessage.phone = !inputValues.phone
        ? AppMessages.phone_empty
        : !validPhoneRgex.test(inputValues.phone)
        ? AppMessages.phone_invalid
        : "";
      setInputValues(obj);

      if (obj.phone && !obj.errorMessage.phone) {
        fetchCustomerdata(obj.phone);
      }
    }
  }

  async function genarateOtp(phoneNum = "") {
    const PHONE_NUMBER = phoneNum != "" ? phoneNum : inputValues.phone;
    setLoadSpin(true);
    try {
      const response = await fetch(
        `/api/Guest/generateOTP?phonenumber=${PHONE_NUMBER}`,
        { method: "GET" }
      );
      if (!response.ok) {
        if (response.status === 400) {
          const text = await response.text();
          if (phoneNum == "") {
            setApiError(text);
          }
        }
        setLoadSpin(false);
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        if (data?.responseMsg === "Verified") {
          window.location.href = "/tell-us-about-you";
        } else if (data?.responseMsg === "Success") {
          setotpPopup(true);
        }
      }
      setLoadSpin(false);
    } catch (err) {
      setLoadSpin(false);
    }
  }

  async function fetchCustomerdata(phoneNumber) {
    setApiError("");
    setLoadSpin(true);
    try {
      const response = await fetch(
        `/api/Customer/CheckUserExist?PhoneNumber=${phoneNumber}`,
        { method: "GET" }
      );
      if (!response.ok) {
        if (response.status === 404) {
          window.location.href = "/tell-us-about-you";
        }
      }

      const data = await response.json();
      if (
        data?.responseCode === 200 &&
        (data?.responseMsg === "WebUser Exist" ||
          data?.responseMsg === "Guest Exist")
      ) {
        setUserType(data?.responseMsg);
        setExistWebNumberPopup(true);
      }
      else if (
          data?.responseCode === 200 &&
          data?.responseMsg === "User Exist, No Web Account Found"
      ) {
          setUserType(data?.responseMsg);
  
          setExistCustomerWithoutAccount(true);
      } else if (
        data?.responseCode === 200 &&
        data?.responseMsg === "Customer Exist"
      ) {
        setUserType(data?.responseMsg);
        localStorage.setItem("existPhone", inputValues.phone);
        setExistNumberPopup(true);
      }
    } catch (err) {
    } finally {
      setLoadSpin(false);
    }
    }

    const handleNoAccountClick = () => {
        setLoadSpin(true);
        setExistCustomerWithoutAccount(false);
        window.location.href = "/signup";;
    };

  async function chackUserType(phoneNumber) {
    setApiError("");

    try {
      const response = await fetch(
        `/api/Customer/CheckUserExist?PhoneNumber=${phoneNumber}`,
        { method: "GET" }
      );
      if (!response.ok) {
        if (response.status === 404) {
          //window.location.href = "/tell-us-about-you";
        }
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        setUserType(data?.responseMsg);
      }
    } catch (err) {
    } finally {
      // setLoadSpin(false);
    }
  }

  useEffect(() => {
    if (zipCode.code && zipCode.code == responseZipCode) {
      setServiceListed(true);
    } else {
      setServiceListed(false);
    }
  }, [zipCode.code]);

  const handleExistUserClick = () => {
    setLoadSpin(true);
    setExistNumberPopup(false);
    window.location.href = "/login";
  };

  useEffect(() => {
    localStorage.setItem("existPhone", "");
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (
        existNumberPopup == false &&
        otpPopup == false &&
        existWebNumberPopup == false
      ) {
        if (serviceListed === true) {
          seeYourPriceFun();
        } else {
          validateZip(zipCode.code);
          if (zipCode.code && !zipCode.message) {
            fetchServicesByZipCode();
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    zipCode.code,
    serviceListed,
    inputValues,
    existNumberPopup,
    otpPopup,
    existWebNumberPopup,
  ]);

  useEffect(() => {
    // Focus on zip code input when the page is loaded
    zipCodeRef.current.focus();
    localStorage.setItem("token", "");
  }, []);

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);

  useEffect(() => {
    if (
      pageData?.inputValues?.isBypassNeeded === true &&
      !pageData?.inputValues?.errorMessages
    ) {
      if (pageData?.inputValues?.userType === "Customer") {
        localStorage.setItem("existPhone", pageData?.inputValues?.phoneNumber);
        setExistNumberPopup(true);
      } else {
        setRedirectNeed(true);

        chackUserType(pageData?.inputValues?.phoneNumber);
        genarateOtp(pageData?.inputValues?.phoneNumber);
      }
    }
  }, [pageData]);


    const numberOfImages = 1000; 
    const imageWidth = 200; // Adjust based on your image size

    const bannerImages = Array(numberOfImages).fill(banner); // Create an arr
  return (
    <div className="bg-white">
          <MainHeaderMobile />
      <div
        className="bg-cover pb-3 sm:pb-12 flex justify-center items-center home-banner hero-banner pt-20"
        style={{ height: "640px" }}
      >
        <div className="container md:w-10/12">
          <div className="pt-6 grid grid-cols-1 place-items-center md:grid-cols-2 gap-4 items-center">
            <div className="w-full mx-auto sm:mt-0 mt-3 text-center md:text-left">
              <h2
                className="text-4xl md:leading-normal md:text-5xl lg:leading-normal lg:text-6xl  xl:text-title-text text-white uppercase font-extrabold"
                dangerouslySetInnerHTML={{
                  __html: pageData?.heroSection?.subtitle,
                }}
              ></h2>
              <p className="text-xl md:text-3xl lg:text-subtitle-text font-extrabold text-orange sm:mt-3 mb-2 uppercase">
                {pageData?.heroSection?.title}
              </p>
            </div>
            <div className="pt-4 sm:py-8 w-full max-w-lg mx-auto sm:mt-0 mt-3 flex justify-center md:justify-start">
              <div className="pb-4 w-80 mx-auto">
                <p className="md:text-xl xl:text-2xl text-white font-bold mb-4">
                  ENTER YOUR ZIP CODE
                </p>
                <div className="relative mb-2">
                  <input
                    ref={zipCodeRef}
                    value={zipCode.code}
                    onChange={zipCodeHandle}
                    placeholder="Zip Code"
                    type="text"
                    inputMode="numeric"
                    className="zip-input placeholder-gray-400 tracking-widest font-inter w-full h-14 md:h-16 xl:h-20"
                  />
                  <p className="font-inter text-sm text-red-600 mt-1">
                    {zipCode.message}
                  </p>
                  {zipCode.isValid && (
                    <img
                      src={tickIcon}
                      alt="Valid Zip Code"
                      className="absolute right-2.5 top-5"
                      style={{ width: "24px", height: "24px" }}
                    />
                  )}
                </div>
                <small className="text-white font-inter md:text-sm xl:text-lg mb-5 block">
                  Your zip code helps us find services available in your area.
                </small>

                {serviceListed === true &&  (
                  <div className="pb-4 w-80 mx-auto">
                    <p className="md:text-xl xl:text-2xl text-white font-bold mb-4 ">
                      ENTER YOUR MOBILE NUMBER
                    </p>
                    <div className=" flex justify-center">
                      <div className="relative">
                        <ReactInputMask
                          ref={phoneRef}
                          placeholder="(000) - 000 - 0000"
                          className={`zip-input placeholder-gray-400 tracking-widest font-inter w-full h-14 md:h-16 xl:h-20`}
                          maskChar={null}
                          mask="(999) 999-9999"
                          value={inputValues.phone}
                          onChange={(e) =>
                            phoneNumberHandleChange(
                              e,
                              "phone",
                              validPhoneRgex,
                              AppMessages.phone_empty,
                              AppMessages.phone_invalid
                            )
                          }
                        />
                        <label className="font-inter text-sm text-red-600 mt-1">
                          {inputValues.errorMessage.phone}
                        </label>
                        {inputValues.isPhoneValid && (
                          <img
                            src={tickIcon}
                            alt="Valid Phone Number"
                            className="absolute right-2.5 top-5"
                            style={{ width: "24px", height: "24px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <p className="font-inter text-sm text-red-600 mt-1">
                  {apiError}
                </p>
                {serviceListed === true ? (
                  <button
                    className="btn-primary w-full text-lg font-bold"
                    onClick={() => seeYourPriceFun()}
                  >
                    FIND SERVICES
                  </button>
                ) : (
                  <button
                    className="btn-primary w-full text-lg font-bold"
                    onClick={() => {
                      validateZip(zipCode.code);
                      if (zipCode.code && !zipCode.message) {
                        fetchServicesByZipCode();
                      }
                    }}
                  >
                    FIND SERVICES
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary-blue rounded-lg border-4 border-white min-h-20 md:w-7/12 mx-6 mt-6 md:mx-auto sm:-mt-9 flex flex-col sm:flex-row items-center gap-x-8 gap-y-3 justify-center mb-9 py-5">
        <p className="text-white font-bold text-xl md:text-4xl lg:text-subtitle-text">
          For expedited service, call
        </p>
        <p className="text-orange text-xl md:text-3xl lg:text-subtitle-text font-bold flex gap-2 items-center">
          <img className="ms-2 sm:me-2 h-5 md:h-10" src={phone} alt={phone} />
          <a href={ phoneNumberConstantCall }>{ phoneNumberConstant }</a>
        </p>
      </div>

      <div className="container md:w-10/12 mx-auto px-6 sm:px-0">
        <div className="border-4 border-orange border-opacity-10 rounded-2xl p-10 mb-16 md:mb-40">
          <p className="uppercase text-xl md:text-3xl lg:text-subtitle-text font-bold text-center text-primary-blue mb-4 leading-none">
            We are Your One-Stop Shop for Exterior Home Services
          </p>
          <p className="font-inter text-center leading-5 mb-12">
            Our expert divisions brings you more services, all under one roof.
            <br />
            Every service is performed by our licensed and trained employees,
            not subcontractors.
          </p>

          <div className="grid grid-cols-1 place-items-center sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-y-11 sm:place-items-start">
            {serviceImages.map((service, index) => (
              <div key={index}>
                <button>
                  <span className="block p-5">
                    <img
                      className="max-w-full"
                      src={service.src}
                      alt={service.alt}
                    />
                  </span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="md:w-10/12 md:flex justify-between mx-auto text-center md:text-left items-center mb-12 md:mb-40 px-6 md:px-0">
        <div className="w-full md:w-7/12 md:pr-5 xl:pr-0 mb-12 md:mb-0">
          <p className="text-4xl md:leading-tight md:text-5xl lg:leading-tight lg:text-6xl  xl:text-title-text mb-8 uppercase font-extrabold">
            {pageData?.whyChooseNedStevens?.title}
            <br />{" "}
            <span className="text-orange">
              {pageData?.whyChooseNedStevens?.subtitle}
            </span>
          </p>

          <p
            className="font-inter text-xl leading-8"
            dangerouslySetInnerHTML={{
              __html: `${pageData?.whyChooseNedStevens?.description} `,
            }}
          ></p>
        </div>
        <div className="w-full md:w-5/12 flex justify-center md:justify-end">
          <ul
            className="list-none md:w-full xl:w-10/12"
            style={{ textAlign: "left" }}
          >
            <li
              className="list-orange md:text-xs lg:text-sm xl:text-bas"
              style={{ backgroundColor: "rgba(255, 83, 0, 0.19)" }}
            >
              <img src={iconCheck} alt={iconCheck} />
              {pageData?.whyChooseNedStevens?.highLights?.[0]}
            </li>
            <li
              className="list-orange md:text-xs lg:text-sm xl:text-bas"
              style={{ backgroundColor: "rgba(255, 83, 0, 0.19)" }}
            >
              <img src={iconCheck} alt={iconCheck} />
              {pageData?.whyChooseNedStevens?.highLights?.[1]}
            </li>
            <li
              className="list-orange md:text-xs lg:text-sm xl:text-bas"
              style={{ backgroundColor: "rgba(255, 83, 0, 0.19)" }}
            >
              <img src={iconCheck} alt={iconCheck} />
              {pageData?.whyChooseNedStevens?.highLights?.[2]}
            </li>
            <li
              className="list-orange md:text-xs lg:text-sm xl:text-bas"
              style={{ backgroundColor: "rgba(255, 83, 0, 0.19)" }}
            >
              <img src={iconCheck} alt={iconCheck} />
              {pageData?.whyChooseNedStevens?.highLights?.[3]}
            </li>
            <li
              className="list-orange md:text-xs lg:text-sm xl:text-bas"
              style={{ backgroundColor: "rgba(255, 83, 0, 0.19)" }}
            >
              <img src={iconCheck} alt={iconCheck} />
              {pageData?.whyChooseNedStevens?.highLights?.[4]}
            </li>
          </ul>
        </div>
      </div>

      <div className="md:w-10/12 mb-12 md:mb-32 mx-auto px-6 md:px-0">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 gap-y-11 place-items-center">
          <div>
            <img src={badge1} alt={badge1} />
          </div>
          <div>
            <img src={badge2} alt={badge2} />
          </div>
          <div>
            <img src={badge3} alt={badge3} />
          </div>
          <div>
            <img src={badge4} alt={badge4} />
          </div>
        </div>
      </div>

      <FooterMobile />

      {/* Already exist phone nuber */}
      {existNumberPopup && (
        <div
          id="popup-modal-1"
          tabindex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
          style={{ display: "flex", backgroundColor: "rgba(0, 0, 0, 0.26)" }}
        >
          <div className="relative p-4 w-full max-w-xl max-h-full">
            <div className="relative bg-white rounded-lg shadow px-4">
              <div className="bg-white md:p-5 p-4 rounded-lg text-center">
                <img className="mx-auto" src={featured1} alt={featured1} />
                <h2 className="text-lg font-extrabold mt-3 mb-1">
                  Account Already Exists
                </h2>
                <h3 className="mb-5 text-xs font-medium font-inter text-gray-500 leading-tight">
                  This number is already linked to an account. Please proceed by
                  logging in with your credentials.
                </h3>
                <div className="grid grid-cols-2 gap-5 mt-6 mb-2">
                  <button
                    className="back-btn px-12"
                    onClick={() => setExistNumberPopup(false)}
                  >
                    CANCEL
                  </button>
                  <button
                    data-modal-target="popup-modal-2"
                    data-modal-toggle="popup-modal-2"
                    className="btn-primary px-12"
                    onClick={handleExistUserClick}
                  >
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Web User Phone Otp */}
      {otpPopup === true && (
        <WebUserVerificationNew
          setOpen={setotpPopup}
          spinOpen={setLoadSpin}
          phoneNumber={inputValues.phone}
          userType={userType}
          redirectNeed={redirectNeed}
          pageData={pageData}
        />
      )}

      {existWebNumberPopup && (
        <div
          id="popup-modal-1"
          tabindex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
          style={{ display: "flex", backgroundColor: "rgba(0, 0, 0, 0.26)" }}
        >
          <div className="relative p-4 w-full max-w-xl max-h-full">
            <div className="relative bg-white rounded-lg shadow px-4">
              <div className="bg-white md:p-5 p-4 rounded-lg text-center">
                <img className="mx-auto" src={featured1} alt={featured1} />
                <h2 className="text-lg font-extrabold mt-3 mb-1">
                  WE FOUND YOU
                </h2>
                <h3 className="mb-5 text-xs font-medium font-inter  leading-tight">
                  We have located your information in our database. <br />
                  Please verify your mobile number to proceed.
                </h3>
                <div className="grid grid-cols-2 gap-5 mt-6 mb-2">
                  <button
                    className="back-btn px-12"
                    onClick={() => setExistWebNumberPopup(false)}
                  >
                    CANCEL
                  </button>
                  <button
                    data-modal-target="popup-modal-2"
                    data-modal-toggle="popup-modal-2"
                    className="btn-primary px-12"
                    onClick={() => {
                      setLoadSpin(true);
                      setExistWebNumberPopup(false);
                      genarateOtp();
                    }}
                  >
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
          )}


          {existCustomerWithoutAccount && (
              <div
                  id="popup-modal-1"
                  tabindex="-1"
                  className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                  style={{ display: "flex", backgroundColor: "rgba(0, 0, 0, 0.26)" }}
              >
                  <div className="relative p-4 w-full max-w-xl max-h-full">
                      <div className="relative bg-white rounded-lg shadow px-4">
                          <div className="bg-white md:p-5 p-4 rounded-lg text-center">
                              <img className="mx-auto" src={featured1} alt={featured1} />
                              <h2 className="text-lg font-extrabold mt-3 mb-1">
                                  You're almost there!
                              </h2>
                              <h3 className="mb-5 text-xs font-medium font-inter text-gray-500 leading-tight">
                                  Link your account now to start enjoying our online shopping experience.
                              </h3>
                              <div className="grid grid-cols-2 gap-5 mt-6 mb-2">
                                  <button
                                      className="back-btn px-12"
                                      onClick={() => setExistCustomerWithoutAccount(false)}
                                  >
                                      CANCEL
                                  </button>
                                  <button
                                      data-modal-target="popup-modal-2"
                                      data-modal-toggle="popup-modal-2"
                                      className="btn-primary px-12"
                                      onClick={handleNoAccountClick}
                                  >
                                      PROCEED
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          )}

      {loadeSpin && <LoaderSpinner />}
    </div>
  );
}

export default page(HomePageMobile);
